var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "flex flex-wrap items-center" },
      [
        _c("span", [_vm._v(_vm._s(this.params.data.uniqueRef))]),
        _c("feather-icon", {
          attrs: {
            title: "Copy to clipboard",
            icon: "ClipboardIcon",
            svgClasses: "h-5 w-5 ml-1 hover:text-primary cursor-pointer",
          },
          on: { click: _vm.onCopyToClipboard },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }